.Type2-Header {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  // background-color: black;
  // &.scroll {
  //   height: 70px;
  //   background-color: #ba56568f;
  // }
  // @media only screen and (max-width: 576px) {
  //   height: 100px !important;
  //   position: relative !important;
  //   background-color: rgb(0 0 0 / 11%);
  // }
}
.Type2-Header-Scroll {
  background-image: linear-gradient(#5b5b5b9c, #00000066);
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  // @media only screen and (max-width: 576px) {
  //   height: 100px !important;
  //   position: relative !important;
  // }
}

.Type2-HeaderBox1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  padding-left: 50px;
  img {
    width: 240px;
    height: 120px;
    object-fit: contain;
  }
}
.Type2-HeaderBox2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  padding-right: 50px;
}
.Type2-HeaderBox2btn {
  border: 1px solid rgb(255, 255, 255);
  padding: 15px 30px;
  border-radius: 100px;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  padding-bottom: 8px;
  color: #fff;
  height: 50px;
  cursor: pointer;
}
.Type2-HeaderBox2btn2 {
  border: 1px solid rgb(255, 255, 255);
  margin-right: 20px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #fff;
}
.Type2-HeaderBox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.type2-Block1 {
  color: #fff;
  height: 100vh;
  width: 100%;
  margin: 0px;
  top: 0px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.type2-Block1overlay {
  background-image: linear-gradient(#5131315f, #383232af, #4d2a2a00);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 100px;
}
.type2-Block1Box1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;
  margin-left: 50px;
}
.type2-Block1txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 40px;
}
.type2-Block1txt2 {
  font-family: "Montserrat-Bold";
  font-size: 100px;
}
.type2-Block1Box2 {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  align-items: flex-end;
  padding-right: 10px;
  height: 100%;
}
.type2-Block1txt3 {
  margin-bottom: 10px;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.type2-Block1Box2btn1 {
  border: 1px solid rgb(255, 255, 255);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 100px;
}
//////////----TYPE3_BLOCK2----///////////////
.type3-Block2Box1 {
  width: 100%;
  min-height: 80vh;
  // min-height: 480px;
}
.type3-BlockCol1 {
  border-right: 2px solid white;
}
.type3-Block2Box2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#464141, grey);
  @media only screen and (max-width: 576px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 20px;
  }
}
.type3-Block2Txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 25px;
  color: white;
  @media only screen and (max-width: 576px) {
    padding: 20px;
    color: white;
  }
}
.type3-Block2Txt2 {
  margin-top: 16px;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: white;
  width: 95%;
}
.type3-Block2Btn {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: white;
}
.type3-Block2Box3 {
  width: 280px;
  border: 2px solid white;
  padding-left: 6px;
  margin-top: 26px;
}
.type3-Block2Txt3 {
  font-family: "Montserrat-Bold";
  font-size: 36px;
  color: white;
}
.type3-Block2Box4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.type3-Block2Box5 {
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 576px) {
    padding: 10px;
    width: 100%;
  }
}
.type3-Block2Arrow1 {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 100%;
  display: grid;
  place-items: center;
}
.type3-Block2Box6 {
  height: 80vh;
  @media only screen and (max-width: 576px) {
    height: 50vh;
  }
}
///////------TYPE3_BLOCK3------///////////
.type3-Block3Box1 {
  width: 100%;
  height: 100%;
  padding: 30px;
  @media only screen and (max-width: 576px) {
    padding: 10px;
  }
}
.type3-Block3Box3 {
  width: 380px;
  height: 500px;
}
.type3-Block3Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.type3-Block3Box2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.type3-Block3Box4 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.type3-Block3Box5 {
  width: 100%;
  padding: 16px;
}
.type3-Block3Box6 {
  padding: 16px;
  text-align: end;
}
.type3-Block3txt1 {
  font-family: "Montserrat-Bold";
  font-size: 30px;
  letter-spacing: 1px;
}
.type3-Block3Txt2 {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 20px;
  margin-top: 20px;
}
.type3-Block3Txt5 {
  font-family: "Montserrat-Bold";
  font-size: 12px;
  margin-top: 20px;
}
.type3-Block3Txt6 {
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
}
///////////------TYPE3_BLOCK4---------///////////
.type3-Block4Box1 {
  width: 100%;
  width: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.075);
  @media only screen and (max-width: 576px) {
    padding: 10px;
  }
}
.type3-Block4Box2 {
  height: 100%;
  width: 100%;
  padding: 26px;
  // order: 1;
  @media only screen and (max-width: 576px) {
    padding: 10px;
  }
}
.type3-Block4Box3 {
  width: 100%;
}
.type3-Block4Box4 {
  height: 100%;
  display: flex;
  justify-content: center;
  // order: 2;
}
.type3-Block4Box5 {
  width: 380px;
  height: 500px;
}
.type3-Block4Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.type3-Block4Txt2 {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 20px;
  margin-top: 20px;
}
.type3-Block4Txt5 {
  font-family: "Montserrat-Bold";
  font-size: 12px;
  margin-top: 24px;
  text-align: end;
}
.type3-Block4Txt6 {
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
  text-align: end;
}
//////-----TYPE3_BLOCK5----//////////
.type3-Block5Box1 {
  height: 100%;
  @media only screen and (max-width: 576px) {
    padding-bottom: 20px;
  }
}
.type2-Block1Box2-card {
  @media only screen and (max-width: 576px) {
    display: none;
  }
}

.type3-Block5Box3 {
  width: 100%;
  height: 100%;
}
.type3-Block5Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.type3-Block5Box4 {
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
}
.type3-Block5Box5 {
  width: 100%;
}
.type3-Block5Txt1 {
  font-family: "Montserrat-Bold";
  font-size: 24px;
  color: skyblue;
}
.type3-Block5Txt2 {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  margin-top: 20px;
}
.type3-Block5Txt3 {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  margin-top: 20px;
}
.type3-Block5Txt4 {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  margin-top: 20px;
}
.type3-Block5Btn {
  max-width: 200px;
  height: 30px;
  font-family: "Montserrat-Bold";
  font-size: 12px;
  border: none;
  background-color: transparent;
  text-decoration-line: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
  text-decoration-style: solid;
  text-align: start;
  margin-top: 16px;
}
/////////----TYPE_FOOTER----/////////
.Type3-Footer {
  min-height: 300px;
  width: 100%;
  background-color: black;
}
.type3-FooterBox1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.type3-FooterBox2 {
  width: 200px;
}
.type3-FooterImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.type3-FooterBox3 {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  margin-top: 20px;
  @media only screen and (max-width: 576px) {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}
.type3-FooterBox4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.type3-FooterTxt1 {
  color: white;
  font-family: "Montserrat-Medium";
  font-size: 12px;
}
.type3-FooterTxt2 {
  color: white;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  margin-top: 10px;
}
.type3-FooterBox5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.type3-FooterBox6 {
  width: 200px;
}
.type3-FooterBox7 {
  width: 200px;
  margin-top: 20px;
}

.type3-FooterText3 {
  color: white;
  font-family: "Montserrat-Semibold";
  font-size: 12px;
}
.type3-FooterText4 {
  margin-top: 10px;
  color: white;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  padding-left: 4px;
}

////////-------MODAL------/////////////////////
.type3-ModalBox1 {
  // padding: 30px ;
  height: 100%;
}
.type3-ModalBox2 {
  padding: 30px 0px 30px 30px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.type3-ModalTxt1 {
  font-family: "Montserrat-Bold";
  font-size: 28px;
  color: skyblue;
}
.type3-ModalTxt2 {
  font-family: "Montserrat-Semibold";
  font-size: 20px;
  color: #a1a1a1;
}
.type3-ModalTxt3 {
  font-family: "Montserrat-Semibold";
  font-size: 16px;
  color: #757575;
  margin-top: 10px;
}
.type3-ModalTxt4 {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 6px;
  line-height: 18px;
}
.type3-Modal {
  width: 992px !important;
  position: absolute;
  right: 100px;
  top: 20px;
}
.type3-ModalBox3 {
  background-color: #dfdfdf5c;
  height: 100%;
  padding: 30px 30px 30px 30px;
  border-radius: 4px;
}
.type3-ModalLabel {
  font-family: "Montserrat-SemiBold";
  font-size: 10px !important;
  color: #00000070;
  width: 100%;
  height: 70px;
}
:where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-content {
  border-radius: 4px !important;
  padding: 0 !important;
}
.type3-ModalBtn {
  width: 130px;
  height: 35px;
  margin-top: 28px;
  background-color: red;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  color: white;
}
.type3-ModalBtn:hover {
  color: white !important;
  border: white;
}
.type3-ModalBox4 {
  text-align: end;
}
///////////-------ABOUTUS-----///////////
.type3-AboutBox1 {
  // min-height: 100vh;
  padding: 30px;
  @media only screen and (max-width: 576px) {
    padding: 0px;
  }
}
.type3-AboutBox3 {
  width: 557px;
  height: 68vh;
  @media only screen and (max-width: 576px) {
    height: 50vh;
  }
}
.type3-AboutBox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media only screen and (max-width: 576px) {
    height: 100%;
    padding: 20px;
  }
}
.type3-AboutImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.text-Div {
  width: 60%;
  height: 70%;
}
.textContent-Div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  @media only screen and (max-width: 576px) {
    height: 100%;
    padding: 0px;
  }
}
.textDiv {
  width: 80%;
}
.aboutgroup-nav {
}
// --------------MOBILE-------------------///
@media only screen and (max-width: 576px) {
  .type3-Block3Order1 {
    order: 1 !important;
  }
  .type3-Block3Order2 {
    order: 2 !important;
  }
  .type3-Block3Box2 {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }
  .type3-Block3Box3 {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  .type3-Block4Box5 {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  .type3-Block4Box4 {
    height: 100%;
  }
  .type3-Block5Box4 {
    height: 100%;
  }
  .type3-Block5Box4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .type2-Block1txt2 {
    font-family: "Montserrat-Bold";
    font-size: 78px !important;
  }
  .type3-Block2Box4 {
    height: 100%;
  }
  .Type2-Header-Scroll {
    background-color: transparent !important;
  }
  .Type2-HeaderBox2btn {
    width: 160px;
  }
  .type3-Block5Box5 {
    width: 95%;
  }
  .type3-Modal {
    width: 100%;
  }
  .type3-FooterBox4 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
  }
  // .Type3-Footer{
  //   padding: 30px;
  // }
  .type3-FooterBox1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    padding: 10px;
  }
  .type3-FooterBox2 {
    width: 186px;
  }
  .type3-FooterBox5 {
    padding: 5px !important;
  }
  .type3-FooterText4 {
    font-size: 10px;
  }
  .type3-FooterBox7 {
    width: 100%;
  }
  .Type2-HeaderBox3 {
    display: none !important;
    padding-left: 0px !important;
  }
  // <-------------dvision class------------>
}
.type1-division {
  margin-top: 50px;
  margin-bottom: 50px;
}

.division-txt4 {
  font-family: "Montserrat-Bold";
  text-align: left;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  font-size: 50px;
}

.division-line {
  background-color: red;
  width: 20%;
  height: 3px;
}

.division-txt5 {
  text-align: left;
  font-family: Lato, "sans-box";
  font-size: 20px;
  line-height: 30px;
}

.type1-division4Item {
  display: flex;
  flex-direction: row;
  border-top: 2px solid rgb(218, 218, 218);
  border-left: 2px solid rgb(218, 218, 218);
  margin-bottom: 30px;
}

.type1-division4Img {
  width: 150px;
  height: 150px;
  border: 2px solid rgb(218, 218, 218);
}

.type1-division4Item2 {
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.type1-division4txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 25px;
  color: #000;
  text-align: right;
}

.type1-division4txt2 {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #ff0000;
  text-align: right;
}
.type3-headerToggle {
  @media only screen and (min-width: 576px) {
    display: none;
    margin-right: 10px !important;
  }
}
.type3-headerDrwr1 {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.type3-headerDrwrTxt1 {
  font-family: "Montserrat-Bold";
  font-size: 22px;
  line-height: 44px;
}
.type3-headerDrwrImg1 {
  width: 100%;
  height: 200px;
}
.type3-headerDrwrImg {
  width: 100%;
  height: 100%;
}
.type3-aboutGroupTxt {
  font-family: "Montserrat-Bold";
  font-size: 38px;
}
.type3-aboutGroupTxt2 {
  font-family: "Montserrat-Medium";
  font-size: 16px;
}
.aboutGroup-Img {
  object-fit: cover;
  // opacity: 0.5 !important;
}
.aboutGrp-Txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  color: white;
}
.aboutGrp-Txt2 {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  color: white;
}
